import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'our-story',
    loadChildren: () => import('./our-story/our-story.module').then( m => m.OurStoryPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
  },
  {
    path: 'search/promotions',
    loadChildren: () => import('./search-promotions/search-promotions.module').then( m => m.SearchPromotionsPageModule)
  },
  {
    path: 'promotions-yoga',
    loadChildren: () => import('./promotions-yoga/promotions-yoga.module').then( m => m.PromotionsYogaPageModule)
  },
  {
    path: 'promotions-dance',
    loadChildren: () => import('./promotions-dance/promotions-dance.module').then( m => m.PromotionsDancePageModule)
  },
  {
    path: 'promotions-group-fitness',
    loadChildren: () => import('./promotions-group-fitness/promotions-group-fitness.module').then( m => m.PromotionsGroupFitnessPageModule)
  },
  {
    path: 'promotion-filters',
    loadChildren: () => import('./modals/promotion-filters/promotion-filters.module').then( m => m.PromotionFiltersPageModule)
  },
  {
    path: 'auto-complete-place',
    loadChildren: () => import('./modals/auto-complete-place/auto-complete-place.module').then( m => m.AutoCompletePlacePageModule)
  },
  {
    path: 'promotion-description',
    loadChildren: () => import('./modals/promotion-description/promotion-description.module').then( m => m.PromotionDescriptionModule)
  },
  {
    path: 'promotion/:id',
    loadChildren: () => import('./promotion-details/promotion-details.module').then( m => m.PromotionDetailsPageModule)
  },
  {
    path: 'purchase-promotion',
    loadChildren: () => import('./purchase-promotion/purchase-promotion.module').then( m => m.PurchasePromotionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-promotion-result',
    loadChildren: () => import('./purchase-promotion-result/purchase-promotion-result.module')
    .then( m => m.PurchasePromotionResultPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reservations',
    loadChildren: () => import('./reservations/reservations.module').then( m => m.ReservationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'venue/:id',
    loadChildren: () => import('./studio/studio.module').then( m => m.StudioPageModule)
  },
  {
    path: 'venue/:id/buy/:planId',
    loadChildren: () => import('./purchase-studio/purchase-studio.module').then( m => m.PurchaseStudioModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'venue/:id/buy/:planId/result',
    loadChildren: () => import('./purchase-studio-result/purchase-studio-result.module').then( m => m.PurchaseStudioResultModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'studio-filters',
    loadChildren: () => import('./modals/studio-filters/studio-filters.module').then( m => m.StudioFiltersPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset_password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password-success',
    loadChildren: () => import('./reset-password-success/reset-password-success.module').then( m => m.ResetPasswordSuccessPageModule)
  },
  {
    path: 'emailVerified',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailModule)
  },
  {
    path: 'chat/:roomId',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chats',
    loadChildren: () => import('./chat-list/chat-list.module').then( m => m.ChatListModule),
    canActivate: [AuthGuard]
  },
  { 
    path: ':username',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  }




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
