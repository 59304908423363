import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GeoCodeService } from '../../services/geo-code.service';
declare var google;

@Component({
  selector: 'app-auto-complete-place',
  templateUrl: './auto-complete-place.page.html',
  styleUrls: ['./auto-complete-place.page.scss'],
})
export class AutoCompletePlacePage implements OnInit {
  autocompleteItems;
  autocomplete;
  latitude = 0;
  longitude = 0;
  geo: any;
  selectedPlace: string;

  service = new google.maps.places.AutocompleteService();
  constructor(private modalCtrl: ModalController,
              private zone: NgZone,
              private geoCodeService: GeoCodeService,
            ) {
    this.autocompleteItems = [];
    this.autocomplete = {
      query: ''
    };
  }

  chooseItem(item: any) {
    this.modalCtrl.dismiss(item, 'cancel');
    this.geo = item;
    this.geoCode(this.geo); // convert Address to lat and long
  }

  updateSearch() {

    if (this.autocomplete.query === '') {
     this.autocompleteItems = [];
     return;
    }

    const me = this;
    this.service.getPlacePredictions({
    input: this.autocomplete.query,
    componentRestrictions: {
      country: 'ca'
    }
   }, (predictions, status) => {
     me.autocompleteItems = [];
     me.zone.run(() => {
     if (predictions != null) {
        predictions.forEach((prediction) => {
          me.autocompleteItems.push(prediction.description);
        });
       }
     });
   });
  }

  // convert Address string to lat and long
  geoCode(address: any) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
    this.latitude = results[0].geometry.location.lat();
    this.longitude = results[0].geometry.location.lng();
    this.geoCodeService.codeAddress(address).subscribe( data => {
      const geometry = data[0].geometry;
      if (geometry.bounds) {
      const boundaries = {
            NE: this.geoCodeService.googleLatLngToJson(geometry.bounds.getNorthEast()),
            SW: this.geoCodeService.googleLatLngToJson(geometry.bounds.getSouthWest())
        };
    }
    });
    this.selectedPlace = address.toString();
   });
 }

  ngOnInit() {
  }

}
