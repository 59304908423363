import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { AlertController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import {BnNgIdleService} from 'bn-ng-idle';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private currentUser: any = null;
  public firstName: string;
  public lastName: string;
  public emailId: string;
  public userRole: string;
  public userProfile:any;
  public showChatIcon = true;
  public appPages = [
    {
      title: 'Home',
      url: '/home'
    },
    {
      title: 'Search',
      url: '/list'
    },
    {
      title: 'Login',
      url: '/login'
    },
    {
      title: 'Sign up',
      url: '/signup'
    },
    {
      title: 'Help',
      url: '/list'
    }
  ];
  public isIframeLoader = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private events: Events,
    private authService: AuthService,
    private alertController: AlertController,
    private router: Router,
    private bnIdle: BnNgIdleService,
    private ga: GoogleAnalytics
  ) {
    this.initializeApp();
    this.currentUser = JSON.parse(localStorage.getItem('pToken'));
    if (this.currentUser) {
      this.authService.isLoggedIn = true;
      this.firstName = this.currentUser.user.first_name;
      this.lastName = this.currentUser.user.last_name;
      this.emailId = this.currentUser.user.email;
      this.userRole = this.currentUser.user.role;
      this.userProfile = this.currentUser.user;
      this.authService.checkUser();
    }
    events.subscribe('user:login', (loggedInUser) => {
      this.firstName = loggedInUser.user.first_name;
      this.lastName = loggedInUser.user.last_name;
      this.emailId = loggedInUser.user.email;
      this.userRole = loggedInUser.user.role;
      this.userProfile = loggedInUser.user;
    });

    events.subscribe('user:logout', (loggedInUser) => {
      this.firstName = null;
      this.lastName = null;
      this.emailId = null;
      this.currentUser = null;
      this.userRole = null;
      this.userProfile = {};
    });

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // this.ga.startTrackerWithId('G-HTP42S7Y3P')
      // .then(() => {
      //   this.ga.trackView('Mobile App')
      //   .then(() => {
      //   })
      //   .catch(
      //     error => console.log(error)
      //   );
      // }).catch(
      //   error => console.log("Google Analytics Error: " + error)
      // );
      
      this.bnIdle.startWatching(1200).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          // Redirect to login page after idle time out in 20 mints
          this.authService.logout();
          this.router.navigate(['/login']);
        }
      });
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showChatIcon = !event.url.includes('/chat');
      }
    });
    this.isIframeLoader = window !== window.parent;
  }


  onChatClick(){
    this.router.navigate(['/chats']);
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'popup-cancel-button',
          handler: () => {
            // console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          cssClass: 'button-positive',
          handler: () => {
            this.authService.logout();
          }
        }
      ]
  });
    await alert.present();
  }
  async logoutClicked() {
    this.presentAlertConfirm();
  }

  async becomeInstructorClicked() {
    const alert = await this.alertController.create({
      message: 'Become an Instructor is easy. Just login via desktop site on www.promoga.com',
      buttons: ['OK']
    });
    await alert.present();
  }

  trackEvent(val) {
    this.ga.trackEvent('Category', 'Action', 'Label', val);
  }
}
