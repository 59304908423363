import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
export class StudioFiltersPage {
    constructor(nav, navParams, modalCtrl) {
        this.nav = nav;
        this.navParams = navParams;
        this.modalCtrl = modalCtrl;
        this.showPicker = false;
        this.filters = {};
    }
    ngOnInit() {
        const modalParams = Object.assign(this.navParams.data, {});
        this.mFilters = modalParams.filters;
        this.filters = this.mFilters;
        this.mInstructorList = modalParams.instructorList;
        this.mclassTypeList = modalParams.classTypeList;
        this.mclassTimings = modalParams.classTimings;
        this.mClassType = modalParams.classType;
        this.filters.classType = modalParams.classType;
        this.filters.instructorList = modalParams.instructorList;
        this.filters.classTypeList = modalParams.classTypeList;
        this.filters.classTimings = modalParams.classTimings;
        this.filters.dateFilter = modalParams.dateFilter;
        this.setColorType();
    }
    onClassTypeChange($event) {
        if (this.filters.classType == $event) {
            this.filters.classType = '';
        }
        else {
            this.filters.classType = $event;
        }
        this.setColorType();
    }
    setColorType() {
        this.colorClassType = this.filters.classType === 'Class' ? 'secondary' : 'light';
        this.colorWorkshopType = this.filters.classType === 'Workshop' ? 'secondary' : 'light';
    }
    onPricingChangeHandler($event) {
        this.filters.pricing = $event.target.value;
    }
    onTypeChangeHandler($event) {
        this.filters.type = $event.target.value;
    }
    onDurationTypeChange($event) {
        this.filters.dateFilter = $event.target.value;
    }
    reset() {
        this.filters.classTypeList.forEach(cls => {
            cls.isSelected = true;
            cls.childCat.forEach(cldCls => {
                cldCls.isSelected = true;
            });
        });
        this.filters.classType = '';
        this.filters.classTimings.forEach(time => {
            time.isSelected = true;
        });
        this.filters.instructorList.forEach(inst => {
            inst.isSelected = true;
        });
        this.filters.dateFilter = 'year';
        this.dismissModal();
    }
    cancelModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modalCtrl.dismiss(this.mFilters);
        });
    }
    dismissModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalCtrl.dismiss(this);
        });
    }
    openPicker() {
        this.showPicker = true;
        setTimeout(() => {
            this.picker.open();
        }, 500);
    }
    changeDate(event) {
        const dateVal = event.target.value;
        console.log(dateVal, 'Date');
    }
    onClassTypeCheck(data) {
        this.filters.classTypeList.forEach((item) => {
            if (data) {
                if (data.id === item.id) {
                    item.isSelected = data.isSelected;
                }
                item.childCat.forEach((child) => {
                    child.isSelected = data.isSelected;
                });
            }
            else {
                const selectedCount = item.childCat.filter((child) => {
                    return child.isSelected;
                }).length;
                if (item.childCat.length == selectedCount) {
                    item.isSelected = true;
                }
                else {
                    item.isSelected = false;
                }
            }
        });
    }
}
