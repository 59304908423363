import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
export class AuthGuard {
    constructor(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    canActivate(route, state) {
        const isAuthenticated = this.authService.isLoggedIn;
        if (isAuthenticated) {
            return true;
        }
        else {
            const navigationExtras = this.router.getCurrentNavigation().extras;
            if (!navigationExtras.state) {
                navigationExtras.state = {};
            }
            navigationExtras['state']['redirect'] = this.router.getCurrentNavigation().finalUrl.toString();
            this.router.navigate(['/login'], navigationExtras);
            return false;
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: AuthGuard, providedIn: "root" });
