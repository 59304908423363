<ion-header>
  <ion-toolbar class="padding-horizontal-14 title">
    <span>About this activity</span>
    <ion-icon class="close-icon" name='close' (click)="dismissModal()"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content padding="true">
  <span class="description">
    {{ description }}
  </span>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-button color="tertiary" class="btn-ok" (click)="dismissModal()">
      Ok
    </ion-button>
  </ion-toolbar>
</ion-footer>
