<ion-header>
  <ion-toolbar class="header-color">
    <ion-title class="text-center"></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancelModal()"> <ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="flex align-items-center">
      <ion-col>
        <h4 class="heading">FILTER</h4>
      </ion-col>
      <ion-col>
        <ion-label class="reset_btn" (click)="reset()">Reset All</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="light_bg">
    <ion-grid>
      <ion-row>
        <ion-col>
            <!-- <h4 class="heading">Activity Duration</h4> -->
            <!-- <ion-segment (ionChange)="onDurationChangeHandler($event)" color="secondary">
                <ion-segment-button value="dropin" class="segment_btn">
                  <ion-label>Drop-in</ion-label>
                </ion-segment-button>
                <ion-segment-button value="series" class="segment_btn">
                  <ion-label>Series</ion-label>
                </ion-segment-button>
              </ion-segment> -->
              <!-- <br /> -->
              <h4 class="heading">Pricing</h4>
              <ion-list>
                <ion-radio-group (ionChange)="onPricingChangeHandler($event)">
                  <ion-item>
                    <ion-label>Any Price</ion-label>
                    <ion-radio slot="end" value="any" [checked]="filters.pricing ==='any'" color="secondary"></ion-radio>
                  </ion-item>
              
                  <ion-item>
                    <ion-label>Discount</ion-label>
                    <ion-radio slot="end" value="discount" [checked]="filters.pricing ==='discount'" color="secondary"></ion-radio>
                  </ion-item>
              
                  <ion-item>
                    <ion-label>Free/By Donation</ion-label>
                    <ion-radio slot="end" value="donation" [checked]="filters.pricing ==='donation'" color="secondary"></ion-radio>
                  </ion-item>
                </ion-radio-group>
              </ion-list>
              <br />
              <h4 class="heading">Activity Type</h4>
              <ion-list>
                <ion-radio-group (ionChange)="onTypeChangeHandler($event)" [value]="filters.type">
                  <ion-item>
                    <ion-label>Yoga</ion-label>
                    <ion-radio slot="end" value="yoga" color="secondary"></ion-radio>
                  </ion-item>
              
                  <ion-item>
                    <ion-label>Dance</ion-label>
                    <ion-radio slot="end" value="dance" color="secondary"></ion-radio>
                  </ion-item>
                  <ion-item>
                      <ion-label>Fitness</ion-label>
                      <ion-radio slot="end" value="group-fitness" color="secondary"></ion-radio>
                    </ion-item>
                    <ion-item [hidden]="true">
                      <ion-label>All</ion-label>
                      <ion-radio slot="end" value="all" color="secondary"></ion-radio>
                    </ion-item>
                </ion-radio-group>
              </ion-list>
              <h4 class="heading">Event Type</h4>
              <ion-list>
                <ion-radio-group (ionChange)="onEventTypeChangeHandler($event)" [value]="filters.event_type">
                  <ion-item>
                    <ion-label>Class</ion-label>
                    <ion-radio slot="end" value="class" color="secondary"></ion-radio>
                  </ion-item>
                  <ion-item *ngIf="filters.type ==='group-fitness' || filters.type ==='all'">
                    <ion-label>Bootcamp</ion-label>
                    <ion-radio slot="end" value="bootcamp" color="secondary"></ion-radio>
                  </ion-item>
                  <ion-item *ngIf="filters.type === 'yoga' || filters.type ==='dance' || filters.type ==='all'">
                      <ion-label>Workshop</ion-label>
                      <ion-radio slot="end" value="workshop"  color="secondary"></ion-radio>
                    </ion-item>
                    <ion-item *ngIf="filters.type === 'yoga' || filters.type ==='all'">
                      <ion-label>Teacher Training</ion-label>
                      <ion-radio slot="end" value="teacher-training" color="secondary"></ion-radio>
                    </ion-item>
                </ion-radio-group>
              </ion-list>
              <br/>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <ion-button color="secondary" expand="full" (click)="dismissModal()">Apply Filters</ion-button>
</ion-content>
