import { Pipe, PipeTransform } from '@angular/core';
import { EnvService } from '../env.service';

@Pipe({
  name: 'getImage'
})
export class GetImagePipe implements PipeTransform {
 baseURL;  
 constructor(public envService: EnvService) {
   this.baseURL = envService.BASE_URL;
 }

  transform(value: any, arg: string): any {
    let imgUrl = value.image['50x50'];
    if (arg === 'profile') {
      imgUrl = value.image['50x50'];
    } else if (arg === 'cover') {
      imgUrl = value.image['300x300'];
    }
    const i = imgUrl.indexOf('_collection');
    return this.baseURL + imgUrl.substring(i);
  }
}
