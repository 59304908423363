import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
export class PromotionDescriptionComponent {
    constructor(navParams, modalCtrl) {
        this.navParams = navParams;
        this.modalCtrl = modalCtrl;
        this.description = '';
    }
    ngOnInit() {
        this.description = this.navParams.data.description;
    }
    dismissModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modalCtrl.dismiss();
        });
    }
}
