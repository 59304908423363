import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PromotionFiltersPageRoutingModule } from './promotion-filters-routing.module';

import { PromotionFiltersPage } from './promotion-filters.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PromotionFiltersPageRoutingModule
  ],
  declarations: [PromotionFiltersPage],
  exports: [PromotionFiltersPage]
})
export class PromotionFiltersPageModule {}
