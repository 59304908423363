import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionDescriptionComponent } from './promotion-description.component';
import { PromotionDescriptionComponentRoutingModule } from './promotion-description-routing.module';
import { IonicModule } from '@ionic/angular';




@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PromotionDescriptionComponentRoutingModule
  ],
  declarations: [PromotionDescriptionComponent],
  exports: [PromotionDescriptionComponent]
})
export class PromotionDescriptionModule { }
