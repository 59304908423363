/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promotion-description.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./promotion-description.component";
var styles_PromotionDescriptionComponent = [i0.styles];
var RenderType_PromotionDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromotionDescriptionComponent, data: {} });
export { RenderType_PromotionDescriptionComponent as RenderType_PromotionDescriptionComponent };
export function View_PromotionDescriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "ion-toolbar", [["class", "padding-horizontal-14 title"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["About this activity"])), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "ion-icon", [["class", "close-icon"], ["name", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(7, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "ion-content", [["padding", "true"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(9, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(10, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "span", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 6, "ion-footer", [["class", "ion-no-border"]], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(14, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 4, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(16, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "ion-button", [["class", "btn-ok"], ["color", "tertiary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(18, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Ok "]))], function (_ck, _v) { var currVal_0 = "close"; _ck(_v, 7, 0, currVal_0); var currVal_2 = "tertiary"; _ck(_v, 18, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.description; _ck(_v, 12, 0, currVal_1); }); }
export function View_PromotionDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promotion-description", [], null, null, null, View_PromotionDescriptionComponent_0, RenderType_PromotionDescriptionComponent)), i1.ɵdid(1, 114688, null, 0, i4.PromotionDescriptionComponent, [i3.NavParams, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromotionDescriptionComponentNgFactory = i1.ɵccf("app-promotion-description", i4.PromotionDescriptionComponent, View_PromotionDescriptionComponent_Host_0, {}, {}, []);
export { PromotionDescriptionComponentNgFactory as PromotionDescriptionComponentNgFactory };
