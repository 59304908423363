<ion-header>
  <ion-toolbar class="header-color">
    <ion-title class="text-center"></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancelModal()">
        <ion-icon name="close"></ion-icon
        ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="flex align-items-center">
      <ion-col>
        <h4 class="heading">FILTER</h4>
      </ion-col>
      <ion-col>
        <ion-label class="reset_btn" (click)="reset()">Reset All</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="light_bg_venue ion-no-margin" style="margin-bottom: 0">
    <ion-grid>
      <ion-item>
        <ion-label>Select</ion-label>
        <ion-select
                [(ngModel)]="filters.dateFilter"
                placeholder="Select"
                (ionChange)="onDurationTypeChange($event)"
        >
          <ion-select-option value="today">Today</ion-select-option>
          <ion-select-option value="tomorrow">Tomorrow</ion-select-option>
          <ion-select-option value="week">Week</ion-select-option>
          <ion-select-option value="month">Month</ion-select-option>
          <ion-select-option value="year">Year</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-row>
        <!-- <ion-col>
          <ion-button icon-right (click)="openPicker()" expand="full" color="secondary">
            <ion-datetime #picker pickerFormat="DD MMMM YYYY" [min]="datePickerMin" [(ngModel)]="filters.dateFilter" (ionChange)="changeDate($event)"></ion-datetime>
          </ion-button>
        </ion-col> -->
      </ion-row>
      <ion-row>
        <ion-col>
          <h4 class="heading">Class Type</h4>

          <ion-row>
            <ion-col>
              <ion-button  color="{{colorClassType}}" expand="block"
              class="bold"  (click)="onClassTypeChange('Class')" >Class</ion-button>
            </ion-col>
            <ion-col>
              <ion-button
              color="{{colorWorkshopType}}"
              expand="block"
              (click)="onClassTypeChange('Workshop')"
              >Workshop</ion-button>
            </ion-col>
          </ion-row>
          <!-- <ion-segment
            (ionChange)="onClassTypeChange($event)"
            [value]="filters.classType"
          >
            <ion-segment-button value="Class">
              <ion-label>Class</ion-label>
            </ion-segment-button>
            <ion-segment-button value="Workshop">
              <ion-label>Workshop</ion-label>
            </ion-segment-button>
          </ion-segment> -->
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <h4 class="heading">Instructor</h4>
          <ion-list>
            <ion-item *ngFor="let inst of filters.instructorList">
              <ion-label>{{inst.first_name}} {{inst.last_name}}</ion-label>
              <ion-checkbox
                color="secondary"
                slot="end"
                class="ion-no-margin"
                [(ngModel)]="inst.isSelected"
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <h4 class="heading">Class Type</h4>
          <ion-list item-content>
            <ion-item-group *ngFor="let cat of filters.classTypeList">
              <ion-item>
                <ion-label>{{cat.cat_name}}</ion-label>
                <ion-checkbox
                  color="secondary"
                  slot="end"
                  [(ngModel)]="cat.isSelected"
                ></ion-checkbox>
              </ion-item>
              <ion-list item-content class="ion-no-padding ion-margin-start">
                <ion-item *ngFor="let cldCat of cat.childCat">
                  <ion-label>{{cldCat.cat_name}}</ion-label>
                  <ion-checkbox
                          color="secondary"
                          slot="end"
                          class="ion-no-margin"
                          [(ngModel)]="cldCat.isSelected"
                  ></ion-checkbox>
                </ion-item>
              </ion-list>
            </ion-item-group>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <h4 class="heading">Time</h4>
          <ion-list>
            <ion-item *ngFor="let time of filters.classTimings">
              <ion-label>{{time.name}}</ion-label>
              <ion-checkbox
                      color="secondary"
                      slot="end"
                      class="ion-no-margin"
                      [(ngModel)]="time.isSelected"
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-button color="tertiary" expand="full" class="joinnowBtn" size="default" (click)="dismissModal()">
      APPLY FILTERS
    </ion-button>
  </ion-toolbar>
</ion-footer>
