import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { EnvService } from './env.service';
import { AlertService } from './alert.service';
import { AlertController, Events } from '@ionic/angular';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic-native/native-storage/ngx/index";
import * as i3 from "./env.service";
import * as i4 from "./alert.service";
import * as i5 from "@angular/router";
import * as i6 from "@ionic/angular";
import * as i7 from "./notification.service";
export class AuthService {
    constructor(http, storage, env, alertService, router, events, alertController, notificationService) {
        this.http = http;
        this.storage = storage;
        this.env = env;
        this.alertService = alertService;
        this.router = router;
        this.events = events;
        this.alertController = alertController;
        this.notificationService = notificationService;
        this.isLoggedIn = false;
        this.loginSourceError = '';
        this.existingSource = '';
        this.showFacebookLogin = true;
        this.showGoogleLogin = true;
        this.showPromogaLogin = true;
        this.forgotPasswordSuccess = false;
        this.forgotPasswordError = '';
        this.resetPasswordSuccess = false;
        this.resetPasswordError = '';
    }
    login(loginData) {
        return this.http.post(this.env.API_URL + 'login', loginData, { withCredentials: true })
            .subscribe(data => {
            const parsedResponseData = data;
            if (parsedResponseData.status === true) {
                this.isLoggedIn = true;
                localStorage.setItem('pToken', JSON.stringify(parsedResponseData));
                if (parsedResponseData.user.email_verified === 0 && parsedResponseData.user.source === "promoga") {
                    this.events.publish('user:email_verified', parsedResponseData.user);
                }
                this.events.publish('user:login', data);
                amplitude.logEvent("mobile::user_logged_in", { 'source': 'promoga', 'userId': data['user']['id'] });
                this.notificationService.subscribeNotifications(data['user']['id']);
                this.checkUser();
                if (window.history.state) {
                    const navigationExtras = { state: window.history.state };
                    // this.router.navigate(['/purchase-promotion'], navigationExtras);
                    this.alertService.presentToast('Logged in successfully');
                    if (navigationExtras.state.redirect) {
                        this.router.navigateByUrl(navigationExtras.state.redirect, navigationExtras);
                    }
                    else {
                        this.router.navigateByUrl('/search/promotions');
                    }
                }
                else {
                    this.alertService.presentToast('Logged in successfully');
                    this.router.navigateByUrl('/search/promotions');
                }
            }
            else {
                if (parsedResponseData.status == false && parsedResponseData.error) {
                    if (parsedResponseData.error.hasOwnProperty('code')) {
                        this.processExistingSourceError(parsedResponseData);
                    }
                    else {
                        this.alertService.presentToast(parsedResponseData.error.message);
                    }
                }
                else if (parsedResponseData.error) {
                    this.alertService.presentToast(parsedResponseData.error.message);
                }
                else {
                    this.alertService.presentToast('Login Failed');
                }
            }
        }, error => {
            console.log(error);
        });
    }
    register(signupData) {
        return this.http.post(this.env.API_URL + 'signup', signupData, { withCredentials: true }).subscribe(data => {
            const parsedResponseData = data;
            this.isLoggedIn = true;
            localStorage.setItem('pToken', JSON.stringify(parsedResponseData));
            this.events.publish('user:login', data);
            amplitude.logEvent("mobile::user_signed_up", { 'source': 'promoga', 'userId': data['user']['id'] });
            this.notificationService.subscribeNotifications(data['user']['id']);
            this.checkUser();
            const navigationExtras = { state: window.history.state };
            if (parsedResponseData.status === true) {
                this.emailVerificationMessage(signupData);
            }
            else {
                if (parsedResponseData.status == false && parsedResponseData.error) {
                    if (parsedResponseData.error.hasOwnProperty('code')) {
                        this.processExistingSourceError(parsedResponseData);
                    }
                    else {
                        this.alertService.presentToast(parsedResponseData.error.message);
                    }
                }
                else if (parsedResponseData.error) {
                    this.alertService.presentToast(parsedResponseData.error.message);
                }
                else {
                    this.alertService.presentToast('Login Failed');
                }
            }
        }, error => {
            this.alertService.presentToast('Signup Failed');
        });
    }
    resendEmailVerification() {
        return this.http.post(this.env.API_URL + 'resend_verification', {}, { withCredentials: true })
            .subscribe(data => {
            console.log(data);
        }, error => {
            console.log(error);
        });
    }
    logout() {
        // const headers = new HttpHeaders({
        //   Authorization: this.token.token_type + ' ' + this.token.access_token
        // });
        return this.http.post(this.env.API_URL + 'logout', {}, { withCredentials: true })
            .subscribe(data => {
            localStorage.removeItem('pToken');
            this.events.publish('user:logout');
            this.isLoggedIn = false;
            amplitude.reset();
            this.notificationService.unsubscribeNotifications();
        }, error => {
            console.log(error);
        });
    }
    user() {
        const headers = new HttpHeaders({
            Authorization: this.token.token_type + ' ' + this.token.access_token
        });
        return this.http.get(this.env.API_URL + 'auth/user', { headers })
            .pipe(tap(user => {
            return user;
        }));
    }
    getToken() {
        return this.storage.getItem('pToken').then(data => {
            this.token = data;
            if (this.token != null) {
                this.isLoggedIn = true;
            }
            else {
                this.isLoggedIn = false;
            }
        }, error => {
            this.token = null;
            this.isLoggedIn = false;
        });
    }
    checkUser() {
        const checkData = {
            auth: {
                recover: false
            }
        };
        return this.http.post(this.env.API_URL + 'me', checkData, { withCredentials: true })
            .subscribe(data => {
            const parsedResponseData = data;
            if (parsedResponseData.status === true) {
                this.isLoggedIn = true;
                localStorage.setItem('pToken', JSON.stringify(parsedResponseData));
                this.events.publish('user:reload', data);
                //For generating 10 digit padded user id - for amplitude
                var paddedUserId = data['user']['id'].toString().padStart(10, '0');
                amplitude.setUserId(paddedUserId);
                this.notificationService.subscribeNotifications(data['user']['id']);
            }
            else {
                localStorage.removeItem('pToken');
                this.events.publish('user:logout');
                this.isLoggedIn = false;
                amplitude.reset();
                this.notificationService.unsubscribeNotifications();
            }
        }, error => {
            console.log(error);
        });
    }
    processExistingSourceError(parsedResponseData) {
        if (parsedResponseData.error.code == 'USER_SOURCE_ERROR') {
            this.loginSourceError = parsedResponseData.error.message;
            if (parsedResponseData.error.hasOwnProperty('details')) {
                this.existingSource = parsedResponseData.error.details.existing_source.toLowerCase();
                if (this.existingSource == 'facebook') {
                    this.showFacebookLogin = true;
                    this.showGoogleLogin = false;
                    this.showPromogaLogin = false;
                }
                if (this.existingSource == 'google') {
                    this.showFacebookLogin = false;
                    this.showGoogleLogin = true;
                    this.showPromogaLogin = false;
                }
                if (this.existingSource == 'promoga') {
                    this.showFacebookLogin = false;
                    this.showGoogleLogin = false;
                    this.showPromogaLogin = true;
                }
            }
        }
        else {
            this.alertService.presentToast(parsedResponseData.error.message);
        }
    }
    resetLoginSource() {
        this.loginSourceError = '';
        this.existingSource = '';
        this.showFacebookLogin = true;
        this.showGoogleLogin = true;
        this.showPromogaLogin = true;
    }
    forgotPassword(forgotPasswordData) {
        forgotPasswordData["ismobile"] = true;
        return this.http.post(this.env.API_URL + 'forgot_password', forgotPasswordData, { withCredentials: true }).subscribe(data => {
            const parsedResponseData = data;
            if (parsedResponseData.status === true) {
                this.forgotPasswordSuccess = true;
                this.forgotPasswordError = '';
            }
            else {
                if (parsedResponseData.status == false && parsedResponseData.error) {
                    this.forgotPasswordError = parsedResponseData.error.message;
                }
                else if (parsedResponseData.error) {
                    this.forgotPasswordError = parsedResponseData.error.message;
                }
                else {
                    this.forgotPasswordError = 'Forgot Password Failed';
                }
            }
        }, error => {
            this.forgotPasswordError = 'Forgot Password Failed';
        });
    }
    resetPassword(resetPasswordData) {
        return this.http.post(this.env.API_URL + 'reset_password', resetPasswordData, { withCredentials: true }).subscribe(data => {
            const parsedResponseData = data;
            if (parsedResponseData.status === true) {
                this.resetPasswordSuccess = true;
                this.resetPasswordError = '';
                this.router.navigateByUrl('/reset-password-success');
            }
            else {
                if (parsedResponseData.status == false && parsedResponseData.error) {
                    this.resetPasswordError = parsedResponseData.error.message;
                }
                else if (parsedResponseData.error) {
                    this.resetPasswordError = parsedResponseData.error.message;
                }
                else {
                    this.resetPasswordError = 'Reset Password Failed';
                }
            }
        }, error => {
            this.resetPasswordError = 'Reset Password Failed';
        });
    }
    emailVerificationMessage(signupData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: 'Please verify your email address',
                buttons: [{
                        text: 'Ok',
                        cssClass: 'button-positive',
                        handler: () => {
                            this.events.publish('user:signup', signupData);
                            if (window.history.state) {
                                const navigationExtras = { state: window.history.state };
                                // this.router.navigate(['/purchase-promotion'], navigationExtras);
                                this.alertService.presentToast('Registered successfully');
                                if (navigationExtras.state.redirect) {
                                    this.router.navigateByUrl(navigationExtras.state.redirect, navigationExtras);
                                }
                                else {
                                    this.router.navigateByUrl('/search/promotions');
                                }
                            }
                            else {
                                this.alertService.presentToast('Registered Successfully');
                                this.router.navigateByUrl('/search/promotions');
                            }
                            alert.dismiss();
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
    checkTokenExist(data) {
        return this.http.post(this.env.API_URL + 'token_exist', data, { withCredentials: true });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NativeStorage), i0.ɵɵinject(i3.EnvService), i0.ɵɵinject(i4.AlertService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.Events), i0.ɵɵinject(i6.AlertController), i0.ɵɵinject(i7.NotificationService)); }, token: AuthService, providedIn: "root" });
