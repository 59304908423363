import { Component, OnInit } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-promotion-filters',
  templateUrl: './promotion-filters.page.html',
  styleUrls: ['./promotion-filters.page.scss'],
})
export class PromotionFiltersPage implements OnInit {
  mFilters: any;
  filters: any = {
    duration: 'any',
    pricing: 'any',
    type: 'yoga',
    event_type: 'all'
  };
  constructor(private nav: NavController,
              private navParams: NavParams,
              private modalCtrl: ModalController) {}

  ngOnInit() {
    const modalParams = Object.assign(this.navParams.data, {});
    this.mFilters = modalParams.filters;
    this.filters = this.mFilters;
  }
  onDurationChangeHandler($event) {
    this.filters.duration = $event.target.value;
  }
  onPricingChangeHandler($event) {
    this.filters.pricing = $event.target.value;
  }
  onTypeChangeHandler($event) {
    this.filters.type = $event.target.value;
  }
  onEventTypeChangeHandler($event) {
    this.filters.event_type = $event.target.value;
  }
  reset() {
    this.filters = {
      duration: 'any',
      pricing: 'any',
      type: 'all',
      event_type: 'all'
    };
  }
  async cancelModal() {
    this.modalCtrl.dismiss(this.mFilters);
  }
  async dismissModal() {
    await this.modalCtrl.dismiss(this.filters);
  }
}
