<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" *ngIf="!isIframeLoader">
      <!-- <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header> -->
      <ion-content>
        <ion-grid *ngIf="firstName && lastName">
          <ion-row>
            <ion-col>
                <ion-item style="border: none">
                  <ion-avatar slot="start">
                    <img *ngIf="this.userProfile.image_file_exist == 1" [src]="this.userProfile | getImage:'profile' ">
                    <img *ngIf="this.userProfile.image_file_exist != 1" src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y">
                  </ion-avatar>
                  <ion-label> 
                      <h1 class="profile-name"> {{this.firstName}} {{this.lastName}}</h1>
                      <p class="profile-email">{{this.emailId}}</p>
                  </ion-label>
                </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerDirection]="'root'" [routerLink]="'/home'">
              <ion-label>Home
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" [routerLink]="'/search/promotions'">
              <ion-label>Search
              </ion-label>
            </ion-item>
            <ion-item (click)="becomeInstructorClicked()" *ngIf="!firstName && !lastName">
              <ion-label>Become an Instructor
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" [routerLink]="'/login'" *ngIf="!firstName && !lastName">
              <ion-label>Login
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" [routerLink]="'/signup'" *ngIf="!firstName && !lastName">
              <ion-label>Sign up
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" [routerLink]="'/reservations'" *ngIf="firstName && lastName">
              <ion-label>Reservations
              </ion-label>
            </ion-item>
            <ion-item (click)="becomeInstructorClicked()" *ngIf="firstName && lastName && userRole!='PROMOTER'">
              <ion-label>Become an Instructor
              </ion-label>
            </ion-item>
            <ion-item (click)="logoutClicked()" *ngIf="firstName && lastName">
              <ion-label>Logout
              </ion-label>
            </ion-item>
            <ion-item class="white-link">
              <a href="https://help.promoga.com" ><ion-label>Help
              </ion-label></a>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <div class="menu-alert">
          <img src="assets/images/icon.png">
          <br>
          Purchases, Accounts, Profile update or instructor options can be viewed with the desktop site.
          <br>
          Go to <b>www.promoga.com</b> on desktop
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>  
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" style="margin-bottom: 20px; margin-right: 20px;">  
    <ion-fab-button class="chat-fab-button" (click)="onChatClick()" *ngIf="firstName && lastName && showChatIcon">  
      <ion-icon name="chatbubble"></ion-icon>
    </ion-fab-button>  
  </ion-fab>
</ion-app>
