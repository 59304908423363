import * as i0 from "@angular/core";
export class NotificationService {
    constructor() { }
    subscribeNotifications(userId) {
        OneSignalDeferred.push(function name(onesignal) {
            onesignal.login(userId.toString());
        });
    }
    unsubscribeNotifications() {
        OneSignalDeferred.push(function name(onesignal) {
            onesignal.logout();
        });
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
