import * as i0 from "@angular/core";
export class EnvService {
    constructor() {
        this.BASE_URL = 'https://promoga.com/';
        this.API_URL = `${this.BASE_URL}api/`;
        this.IMAGE_URL = '';
        this.STRIPE_KEY = 'pk_live_3oS1gZhY49cHnQdP525U69h3';
        this.WEAVY_URL = "https://29ddf7d0ea0348538de8b4922641553d.weavy.io";
        this.API_URL = `${this.BASE_URL}api/`;
        this.IMAGE_URL = this.BASE_URL;
    }
}
EnvService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvService_Factory() { return new EnvService(); }, token: EnvService, providedIn: "root" });
