import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
const ɵ0 = () => import("./home/home.module.ngfactory").then(m => m.HomePageModuleNgFactory), ɵ1 = () => import("./list/list.module.ngfactory").then(m => m.ListPageModuleNgFactory), ɵ2 = () => import("./login/login.module.ngfactory").then(m => m.LoginPageModuleNgFactory), ɵ3 = () => import("./signup/signup.module.ngfactory").then(m => m.SignupPageModuleNgFactory), ɵ4 = () => import("./our-story/our-story.module.ngfactory").then(m => m.OurStoryPageModuleNgFactory), ɵ5 = () => import("./privacy/privacy.module.ngfactory").then(m => m.PrivacyPageModuleNgFactory), ɵ6 = () => import("./terms-of-use/terms-of-use.module.ngfactory").then(m => m.TermsOfUsePageModuleNgFactory), ɵ7 = () => import("./search-promotions/search-promotions.module.ngfactory").then(m => m.SearchPromotionsPageModuleNgFactory), ɵ8 = () => import("./promotions-yoga/promotions-yoga.module.ngfactory").then(m => m.PromotionsYogaPageModuleNgFactory), ɵ9 = () => import("./promotions-dance/promotions-dance.module.ngfactory").then(m => m.PromotionsDancePageModuleNgFactory), ɵ10 = () => import("./promotions-group-fitness/promotions-group-fitness.module.ngfactory").then(m => m.PromotionsGroupFitnessPageModuleNgFactory), ɵ11 = () => import("./modals/promotion-filters/promotion-filters.module.ngfactory").then(m => m.PromotionFiltersPageModuleNgFactory), ɵ12 = () => import("./modals/auto-complete-place/auto-complete-place.module.ngfactory").then(m => m.AutoCompletePlacePageModuleNgFactory), ɵ13 = () => import("./modals/promotion-description/promotion-description.module.ngfactory").then(m => m.PromotionDescriptionModuleNgFactory), ɵ14 = () => import("./promotion-details/promotion-details.module.ngfactory").then(m => m.PromotionDetailsPageModuleNgFactory), ɵ15 = () => import("./purchase-promotion/purchase-promotion.module.ngfactory").then(m => m.PurchasePromotionPageModuleNgFactory), ɵ16 = () => import("./purchase-promotion-result/purchase-promotion-result.module.ngfactory")
    .then(m => m.PurchasePromotionResultPageModuleNgFactory), ɵ17 = () => import("./reservations/reservations.module.ngfactory").then(m => m.ReservationsPageModuleNgFactory), ɵ18 = () => import("./studio/studio.module.ngfactory").then(m => m.StudioPageModuleNgFactory), ɵ19 = () => import("./purchase-studio/purchase-studio.module.ngfactory").then(m => m.PurchaseStudioModuleNgFactory), ɵ20 = () => import("./purchase-studio-result/purchase-studio-result.module.ngfactory").then(m => m.PurchaseStudioResultModuleNgFactory), ɵ21 = () => import("./modals/studio-filters/studio-filters.module.ngfactory").then(m => m.StudioFiltersPageModuleNgFactory), ɵ22 = () => import("./forgot-password/forgot-password.module.ngfactory").then(m => m.ForgotPasswordPageModuleNgFactory), ɵ23 = () => import("./reset-password/reset-password.module.ngfactory").then(m => m.ResetPasswordPageModuleNgFactory), ɵ24 = () => import("./reset-password-success/reset-password-success.module.ngfactory").then(m => m.ResetPasswordSuccessPageModuleNgFactory), ɵ25 = () => import("./verify-email/verify-email.module.ngfactory").then(m => m.VerifyEmailModuleNgFactory), ɵ26 = () => import("./chat/chat.module.ngfactory").then(m => m.ChatModuleNgFactory), ɵ27 = () => import("./chat-list/chat-list.module.ngfactory").then(m => m.ChatListModuleNgFactory), ɵ28 = () => import("./profile/profile.module.ngfactory").then(m => m.ProfilePageModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: ɵ0
    },
    {
        path: 'list',
        loadChildren: ɵ1
    },
    {
        path: 'login',
        loadChildren: ɵ2
    },
    {
        path: 'signup',
        loadChildren: ɵ3
    },
    {
        path: 'our-story',
        loadChildren: ɵ4
    },
    {
        path: 'privacy',
        loadChildren: ɵ5
    },
    {
        path: 'terms-of-use',
        loadChildren: ɵ6
    },
    {
        path: 'search/promotions',
        loadChildren: ɵ7
    },
    {
        path: 'promotions-yoga',
        loadChildren: ɵ8
    },
    {
        path: 'promotions-dance',
        loadChildren: ɵ9
    },
    {
        path: 'promotions-group-fitness',
        loadChildren: ɵ10
    },
    {
        path: 'promotion-filters',
        loadChildren: ɵ11
    },
    {
        path: 'auto-complete-place',
        loadChildren: ɵ12
    },
    {
        path: 'promotion-description',
        loadChildren: ɵ13
    },
    {
        path: 'promotion/:id',
        loadChildren: ɵ14
    },
    {
        path: 'purchase-promotion',
        loadChildren: ɵ15,
        canActivate: [AuthGuard]
    },
    {
        path: 'purchase-promotion-result',
        loadChildren: ɵ16,
        canActivate: [AuthGuard]
    },
    {
        path: 'reservations',
        loadChildren: ɵ17,
        canActivate: [AuthGuard]
    },
    {
        path: 'venue/:id',
        loadChildren: ɵ18
    },
    {
        path: 'venue/:id/buy/:planId',
        loadChildren: ɵ19,
        canActivate: [AuthGuard]
    },
    {
        path: 'venue/:id/buy/:planId/result',
        loadChildren: ɵ20,
        canActivate: [AuthGuard]
    },
    {
        path: 'studio-filters',
        loadChildren: ɵ21
    },
    {
        path: 'forgot-password',
        loadChildren: ɵ22
    },
    {
        path: 'reset_password',
        loadChildren: ɵ23
    },
    {
        path: 'reset-password-success',
        loadChildren: ɵ24
    },
    {
        path: 'emailVerified',
        loadChildren: ɵ25
    },
    {
        path: 'chat/:roomId',
        loadChildren: ɵ26,
        canActivate: [AuthGuard]
    },
    {
        path: 'chats',
        loadChildren: ɵ27,
        canActivate: [AuthGuard]
    },
    {
        path: ':username',
        loadChildren: ɵ28
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28 };
