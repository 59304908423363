<ion-header>
  <ion-toolbar>
      <ion-toolbar>
          <ion-searchbar [(ngModel)]="autocomplete.query" showCancelButton="focus"  (ionChange)="updateSearch()"></ion-searchbar>
        </ion-toolbar>
  </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-item *ngFor="let item of autocompleteItems" tappable   (click)="chooseItem(item)">
          {{ item }}
        </ion-item>
    </ion-list>
</ion-content>
