import { Injectable } from '@angular/core';

declare let OneSignalDeferred:any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

    subscribeNotifications(userId){
      OneSignalDeferred.push(function name(onesignal) {
        onesignal.login(userId.toString());
      });
    }

    unsubscribeNotifications(){
      OneSignalDeferred.push(function name(onesignal) {
        onesignal.logout();
      });
    }
}
