import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';
export class PromotionFiltersPage {
    constructor(nav, navParams, modalCtrl) {
        this.nav = nav;
        this.navParams = navParams;
        this.modalCtrl = modalCtrl;
        this.filters = {
            duration: 'any',
            pricing: 'any',
            type: 'yoga',
            event_type: 'all'
        };
    }
    ngOnInit() {
        const modalParams = Object.assign(this.navParams.data, {});
        this.mFilters = modalParams.filters;
        this.filters = this.mFilters;
    }
    onDurationChangeHandler($event) {
        this.filters.duration = $event.target.value;
    }
    onPricingChangeHandler($event) {
        this.filters.pricing = $event.target.value;
    }
    onTypeChangeHandler($event) {
        this.filters.type = $event.target.value;
    }
    onEventTypeChangeHandler($event) {
        this.filters.event_type = $event.target.value;
    }
    reset() {
        this.filters = {
            duration: 'any',
            pricing: 'any',
            type: 'all',
            event_type: 'all'
        };
    }
    cancelModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modalCtrl.dismiss(this.mFilters);
        });
    }
    dismissModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalCtrl.dismiss(this.filters);
        });
    }
}
