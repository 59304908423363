import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { StudioFiltersPageRoutingModule } from './studio-filters-routing.module';

import { StudioFiltersPage } from './studio-filters.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    StudioFiltersPageRoutingModule
  ],
  declarations: [StudioFiltersPage]
})
export class StudioFiltersPageModule {}
