import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const isAuthenticated = this.authService.isLoggedIn;
    
      if (isAuthenticated ) {

          return true;
      } else {

          const navigationExtras: NavigationExtras = this.router.getCurrentNavigation().extras;
          if (!navigationExtras.state) {
            navigationExtras.state = {};
          }
          navigationExtras['state']['redirect'] = this.router.getCurrentNavigation().finalUrl.toString();
          this.router.navigate(['/login'],navigationExtras);
          return false;
      }
  }
}
