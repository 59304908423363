import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module';
import { PromotionFiltersPageModule } from './modals/promotion-filters/promotion-filters.module';
import { AutoCompletePlacePageModule } from './modals/auto-complete-place/auto-complete-place.module';
import { Stripe } from '@ionic-native/stripe/ngx';
import { StudioFiltersPageModule } from './modals/studio-filters/studio-filters.module';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import {BnNgIdleService} from 'bn-ng-idle';
import { PromotionDescriptionModule } from './modals/promotion-description/promotion-description.module';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    PromotionFiltersPageModule,
    StudioFiltersPageModule,
    AutoCompletePlacePageModule,
    PromotionDescriptionModule,
    FormsModule,
    PipesModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    GoogleAnalytics,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Facebook,
    NativeStorage,
    Stripe,
    GooglePlus,
    BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
