import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { GetImagePipe } from './get-image.pipe';
import { SessionDisplayPipe } from './session-display.pipe';
import { RetailPricePipe } from './retail-price.pipe';
import { DiscountPricePipe } from './discount-price.pipe';
import { CustomTermsSplitterPipe } from './custom-terms-splitter.pipe';
import { AmpmdatePipe } from './ampmdate.pipe';
import {TimezoneConverterPipe} from './timezone-converter.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
@NgModule({
    declarations: [GetImagePipe, SessionDisplayPipe, RetailPricePipe, DiscountPricePipe,
        CustomTermsSplitterPipe, AmpmdatePipe, TimezoneConverterPipe, RelativeTimePipe, RelativeTimePipe],
    imports: [CommonModule],
    exports: [GetImagePipe, SessionDisplayPipe, RetailPricePipe, DiscountPricePipe,
        CustomTermsSplitterPipe, AmpmdatePipe, TimezoneConverterPipe, RelativeTimePipe],
})

export class PipesModule {}
