import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-promotion-description',
  templateUrl: './promotion-description.component.html',
  styleUrls: ['./promotion-description.component.scss'],
})
export class PromotionDescriptionComponent implements OnInit {

  public description = '';
  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.description = this.navParams.data.description;
  }

  async dismissModal() {
     this.modalCtrl.dismiss();
  }

}
