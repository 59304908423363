import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  BASE_URL = 'https://promoga.com/';		

  API_URL = `${this.BASE_URL}api/`;
  IMAGE_URL = '';
  STRIPE_KEY = 'pk_live_3oS1gZhY49cHnQdP525U69h3';

  WEAVY_URL = "https://29ddf7d0ea0348538de8b4922641553d.weavy.io";

  constructor() {
    this.API_URL = `${this.BASE_URL}api/`;
    this.IMAGE_URL = this.BASE_URL;
  }
}
