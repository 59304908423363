import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export class GeoCodeService {
    constructor() {
        this.googleLatLngToJson = (LatLng) => {
            return {
                latitude: LatLng.lat(),
                longitude: LatLng.lng()
            };
        };
        this.geocoder = new google.maps.Geocoder();
    }
    /**
     * Reverse geocoding by location.
     *
     * Wraps the Google Maps API geocoding service into an observable.
     *
     * @param latLng Location
     * @return An observable of GeocoderResult
     */
    geocode(latLng) {
        return Observable.create((observer) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.geocoder.geocode({ location: latLng }, ((results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    observer.next(results);
                    observer.complete();
                }
                else {
                    observer.error(status);
                }
            }));
        });
    }
    /**
     * Geocoding service.
     *
     * Wraps the Google Maps API geocoding service into an observable.
     *
     * @param address The address to be searched
     * @return An observable of GeocoderResult
     */
    codeAddress(address) {
        return Observable.create((observer) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.geocoder.geocode({ address }, ((results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    observer.next(results);
                    observer.complete();
                }
                else {
                    console.log('Geocoding service: geocode was not successful for the following reason: '
                        + status);
                    observer.error(status);
                }
            }));
        });
    }
}
GeoCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeoCodeService_Factory() { return new GeoCodeService(); }, token: GeoCodeService, providedIn: "root" });
